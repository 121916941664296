angular.module('app')
    .factory('bookingService', ["$log", "$http", "$q", "localStorageService", "authenticatedStatus", function ($log, $http, $q, localStorageService, authenticatedStatus) {

        var urls = {
            listActiveBookings: '/bookings/active',
            listRecentBookings: '/bookings/recent',
            listLocations: '/bookings/locations',
            listLandmarks: '/bookings/landmarks',
            create: '/bookings/',
            getFare: "/bookings/fare",
            saveTripReason: function (organisationReference) {
                return sprintf("/dashboard/saveTripReason/%s", organisationReference);
            },
            findPassenger: function (mobileNumber) {
                return sprintf("/bookings/%s/passenger", mobileNumber);
            },
            cancel: function (bookingId) {
                return sprintf("/bookings/%s/cancel", bookingId);
            },
            copy: function (bookingId) {
                return sprintf("/bookings/%s/copy", bookingId);
            },
            disputeCollection: function (bookingId) {
                return sprintf("/bookings/%s/disputeCollection", bookingId);
            },
            sendDriverMessage: '/bookings/message'
        };

        var getOrgStorgageKey = function () {
            return authenticatedStatus.user.promise.then(function (user) {
                var oldKey = sprintf("org-reference:%s-default-location", user.props.organisation);
                localStorageService.remove(oldKey);
                return sprintf("org-reference:%s-default-location-v2", user.props.organisation);
            });
        };

        var getLandmarkStorgageKey = function () {
            return authenticatedStatus.user.promise.then(function (user) {
                var oldKey = "ingogo-custom-landmarks-v1";
                localStorageService.remove(oldKey);
                return "ingogo-custom-landmarks-v2";
            });
        };

        var api = {
            listActiveBookings: function () {
                $log.info("Get active bookings");

                return $http.get(urls.listActiveBookings)
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            listRecentBookings: function () {
                $log.info("Get recent bookings ");
                return $http.get(urls.listRecentBookings)
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            listLocations: function () {
                $log.info("Get list locations");
                return $http.get(urls.listLocations)
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            listLandmarks: function () {
                $log.info("Get list landmarks");
                return $http.get(urls.listLandmarks)
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            create: function (trip) {
                $log.info("Create booking: %o", trip);
                return $http.post(urls.create, trip)
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            getFare: function (trip) {
                return $http.post(urls.getFare, trip);
            },
            cancel: function (booking) {
                $log.info("cancel booking: %o", booking);
                return $http.post(urls.cancel(booking.bookingReference), booking)
                    .then(function (resp) {
                        return resp.data;
                    });
            },

            copy: function (id) {
                $log.info("copy booking: %o", id);
                return $http.get(urls.copy(id))
                    .then(function (resp) {
                        return resp.data;
                    });
            },

            findPassenger: function (mobileNumber) {
                $log.info("find passenger by mobile number: %o", mobileNumber);
                return $http.get(urls.findPassenger(mobileNumber)).then(function (resp) {
                    return resp.data;
                });
            },

            disputeCollection: function (booking) {
                $log.info("cancel booking as dispute: %o", booking);
                return $http.post(urls.disputeCollection(booking.bookingReference), booking)
                    .then(function (resp) {
                        return resp.data;
                    });
            },

            sendDriverMessage: function (booking) {
                $log.info("sending driver message: %o, %s", booking);
                return $http.post(urls.sendDriverMessage, booking)
                    .then(function (resp) {
                        return resp.data;
                    });
            },

            setLandmarks: function (landmarks) {
                getLandmarkStorgageKey().then(function (value) {
                    localStorageService.set(value, landmarks);
                });
            },

            getLandmarks: function () {
                return getLandmarkStorgageKey().then(function (key) {
                    var landmarks = localStorageService.get(key);
                    if (landmarks) {
                        var deferred = $q.defer();
                        deferred.resolve(landmarks);
                        return deferred.promise;
                    }

                    return api.listLandmarks().then(function (landmarks) {
                        if (landmarks) {
                            api.setLandmarks(landmarks);
                            return landmarks;
                        }
                    });
                });
            },

            setDefaultLocation: function (location) {
                getOrgStorgageKey().then(function (value) {
                    localStorageService.set(value, location);
                });
            },

            saveTripReason: function (organisationReference, trip) {
                return $http({
                    url: urls.saveTripReason(organisationReference),
                    method: "POST",
                    data: trip,
                });
            },

            getDefaultLocation: function () {
                return getOrgStorgageKey().then(function (key) {
                    var defaultLocation = localStorageService.get(key);
                    if (defaultLocation) {
                        var deferred = $q.defer();
                        deferred.resolve(defaultLocation);
                        return deferred.promise;
                    }

                    return api.listLocations().then(function (locations) {
                        if (locations) {
                            defaultLocation = locations[0];
                            api.setDefaultLocation(defaultLocation);
                            return defaultLocation;
                        }
                    });
                });
            }
        };

        return api;
    }])
;
